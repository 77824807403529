<template>
    <div
        v-if="fair !== null"
        id="messeToolbox"
        :class="{open: ready === true}"
        class="toolbox left">
        <div class="inner">
            <button
                class="btn btn-icon primary btn-close icon-close"
                @click="close" />
            <ul class="tabs">
                <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    :class="{active: tab.name === activeTab}">
                    <button @click="openTab(tab.name)">
                        {{ tab.label }}
                    </button>
                </li>
            </ul>

            <div
                v-if="activeTab === 'messeinfos'"
                class="toolbox-content">
                <div
                    class="badge highlight"
                    :class="{ 'has-countdown': !isStarted }"
                    :style="getBadgeStyle">
                    <h2>
                        {{ fair.title }}
                        <small v-if="fair.startDate && fair.endDate">
                            <template v-if="fair.startDate.isSame(fair.endDate, 'day')">
                                {{ fair.startDate.format('DD.MM.YYYY') }} {{ fair.startDate.format('HH:mm') }} Uhr -
                                {{ fair.endDate.format('HH:mm') }} Uhr
                            </template>
                            <template v-else>
                                {{ fair.startDate.format('DD.MM.YYYY') }} - {{ fair.endDate.format('DD.MM.YYYY') }}
                            </template>
                        </small>
                    </h2>

                    <div
                        v-if="!isStarted"
                        class="countdown">
                        Diese Messe startet {{ differenceInDays }}
                    </div>
                </div>

                <div
                    v-if="fair.fairContentImage"
                    class="img">
                    <img
                        :src="fair.fairContentImage"
                        alt="Messe Bild">
                </div>

                <SVideo
                    v-if="fair.video"
                    :video-data="fair.video" />

                <div
                    class="fair-content"
                    v-html="fair.text" />

                <template v-if="fair.fairLinks || fair.hasFeedbackForm">
                    <ul class="link-bar">
                        <li
                            v-for="(link, index) in fair.fairLinks"
                            :key="index">
                            <SLink :link="link" />
                        </li>
                        <li v-if="fair.hasFeedbackForm && isStarted">
                            <button
                                title="Feedbackformular öffnen"
                                @click="openFeedbackHandler">
                                Feedback abgeben
                            </button>
                        </li>
                    </ul>
                </template>
            </div>
            <div
                v-if="activeTab === 'aussteller'"
                class="toolbox-content">
                <!-- disabled / active -->
                <ul class="pagination">
                    <li
                        v-for="filter in filters"
                        :key="filter.id"
                        :class="{active: selectedFilter !== null && selectedFilter.id === filter.id, disabled: filter.disabled}"
                        @click="setFilter(filter)">
                        {{ filter.title }}
                    </li>
                </ul>

                <ul class="exhibitor-list">
                    <li
                        v-for="profile in filteredFairProfiles"
                        :key="profile.id">
                        <div class="img">
                            <img
                                v-if="profile.companyLogoUrl"
                                :src="profile.companyLogoUrl"
                                alt="Unterrnehmenslogo">
                        </div>
                        <div class="content">
                            <h2>{{ profile.title }}</h2>
                            {{ profile.companySubline }}
                            <ul class="icon-list">
                                <li v-if="fair.hasVideo && profile.hasVideo">
                                    <button
                                        v-if="isStarted"
                                        class="blue icon-video"
                                        @click="toggleProfile(profile, 'video')" />
                                </li>
                                <li v-if="fair.hasChat && profile.hasChat">
                                    <button
                                        v-if="isStarted"
                                        class="green icon-chat"
                                        @click="toggleProfile(profile, 'chat')" />
                                </li>
                                <li v-if="fair.linkToThaff && profile.linkToThaff">
                                    <button
                                        v-if="isStarted"
                                        class="red icon-jobs"
                                        @click="toggleProfile(profile, 'stellen')" />
                                </li>
                            </ul>
                        </div>
                        <div class="buttons">
                            <button
                                v-if="isStarted"
                                class="btn primary"
                                @click="toggleProfile(profile)">
                                Details
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div
                v-if="activeTab === 'program'"
                class="toolbox-content">
                <Programs
                    :programs="fair.programs"
                    :current-moment="currentMoment"
                    :fair="fair" />
            </div>

            <div
                v-if="activeTab === 'news'"
                class="toolbox-content">
                <ul class="news-list">
                    <li
                        v-for="news in fair.news"
                        :key="news.id">
                        <div
                            v-if="news.imageUrl"
                            class="img">
                            <img
                                :src="news.imageUrl"
                                alt="">
                        </div>
                        <div class="content">
                            <small v-if="news.dateFormatted">{{ news.dateFormatted }}</small>
                            <h2>{{ news.headline }}</h2>
                            <span
                                v-if="news.text"
                                v-html="news.text" />

                            <SLink
                                v-if="news.link"
                                :link="news.link"
                                class="btn primary" />
                        </div>
                    </li>
                </ul>
            </div>
            <div
                v-if="activeTab === 'partner'"
                class="toolbox-content">
                <ul
                    v-if="fair.sponsors"
                    class="partner-list">
                    <li
                        v-for="sponsor in fair.sponsors.filter(el => el.isPremium === true)"
                        :key="sponsor.id">
                        <div
                            v-if="sponsor.imageUrl"
                            class="img">
                            <img
                                :src="sponsor.imageUrl"
                                alt="Sponsor Icon">
                        </div>
                        <div class="content">
                            <h2 v-if="sponsor.title">
                                {{ sponsor.title }}
                            </h2>
                            <span
                                v-if="sponsor.text"
                                v-html="sponsor.text" />
                            <ul
                                v-if="sponsor.link || sponsor.phone"
                                class="icon-text-list">
                                <li
                                    v-if="sponsor.phone"
                                    class="icon-phone">
                                    {{ sponsor.phone }}
                                </li>
                                <li
                                    v-if="sponsor.link"
                                    class="icon-home">
                                    <SLink :link="sponsor.link" />
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>

                <ul class="partner-list small grid">
                    <li
                        v-for="sponsor in fair.sponsors.filter(el => el.isPremium === false && el.imageUrl)"
                        :key="sponsor.id"
                        class="col-1-3">
                        <template v-if="sponsor.link">
                            <SLink
                                :link="sponsor.link"
                                class="img">
                                <img
                                    :src="sponsor.imageUrl"
                                    alt="Logo">
                            </SLink>
                        </template>
                        <img
                            v-else
                            :src="sponsor.imageUrl"
                            alt="Logo">
                    </li>
                </ul>
            </div>

            <div
                v-if="activeTab === 'templates'"
                class="toolbox-content">
                <ExampleTemplates />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Programs from '@/components/base/Programs.vue';
import SLink from '@/components/base/SLink.vue';
import SVideo from '@/components/base/SVideo.vue';
import CurrentMoment from '@/compositions/CurrentMoment';
import FairComposition, {EFeedBackSource} from '@/compositions/FairComposition';
import Fair from '@/models/Fair';
import FairProfile from '@/models/FairProfile';
import {ComputedRef, Ref} from '@vue/reactivity';
import moment from 'moment';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import ExampleTemplates from '@/components/ExampleTemplates.vue';

export interface Filter {
    id: string,
    title: string,
    regex: RegExp,
    disabled: boolean
}

interface Tab {
    name: string,
    label: string,
    key: string
}

export default defineComponent({
    name: 'FairInfo',
    components: {
        SVideo,
        SLink,
        Programs,
        ExampleTemplates
    },
    setup() {
        const route = useRoute();
        const store = useStore();

        const currentFair: Fair | null = store.getters['fairs/current'];
        const {
            onMounted: momentOnMounted,
            onUnmounted: momentOnUnmounted,
            currentMoment
        } = CurrentMoment(10000);


        const isStarted: ComputedRef<boolean> = computed(() => {
            return currentMoment.value && currentFair !== null && (currentMoment.value.isAfter(currentFair.startDate));
        });

        const tabs: ComputedRef<Tab[]> = computed(() => {
            const t: Tab[] = [];
            if (currentFair !== null) {
                t.push({name: 'messeinfos', label: 'Messeinfos', key: 'general'});

                if (isStarted.value || currentFair.showProfilesInPreview) {
                    t.push({name: 'aussteller', label: 'Aussteller', key: 'profiles'});
                }

                if (currentFair.programs.length) {
                    t.push({name: 'program', label: 'Programm', key: 'program'});
                }

                if (currentFair.news.length) {
                    t.push({name: 'news', label: 'News', key: 'news'});
                }

                if (currentFair.sponsors.length) {
                    t.push({name: 'partner', label: 'Partner', key: 'partner'});
                }
            }

            if (process.env.NODE_ENV === 'development') {
                t.push({name: 'templates', label: 'Templates', key: 'templates'});
            }

            return t;
        });

        let t = route?.params?.tab ?? '';
        console.log('t', route?.params?.tab);
        if (!t || typeof t !== 'string') {

            // t = 'Messeinfos';
            t = tabs.value[0].name;
        }
        const activeTab: Ref<string> = ref(t);


        const fairProfiles = computed<FairProfile[]>(() => {
            const profiles: FairProfile[] = store.getters['fair-profiles/all'];
            return profiles.slice(0, profiles.length).sort((a, b) => {
                return a.title && b.title && a.title < b.title ? -1 : 1;
            });
        });

        const filteredFairProfiles: ComputedRef<FairProfile[]> = computed(() => {
            if (selectedFilter.value === null) {
                return fairProfiles.value;
            }

            return fairProfiles.value.filter((el: FairProfile) => el.title && selectedFilter.value && selectedFilter.value.regex.test(el.title));
        });

        const filters: Filter[] = [];
        for (let i = 0; i < 26; i++) {
            const letter = (i + 10).toString(36);
            const upper = letter.toUpperCase();

            let regex = null;
            switch (letter) {
                case 'a':
                    regex = '^[aAäÄ]';
                    break;
                case 'e':
                    regex = '^[eEéèê]';
                    break;
                case 'o':
                    regex = '^[oòóOÒÓôÖö]';
                    break;
                case 'u':
                    regex = '^[uUüÜ]';
                    break;
                default:
                    regex = `^[{${letter}{${upper}}]`;
                    break;
            }

            if (regex) {
                const reg = new RegExp(regex);
                let disabled = fairProfiles.value.find((el: FairProfile) => el.title && reg.test(el.title)) || null;

                filters.push({id: letter, title: upper, regex: reg, disabled: disabled === null});
            }
        }

        watch(() => route.params, (params) => {
            const tab = params?.tab ?? null;
            if (tab === null) {
                activeTab.value = tabs.value[0].name;
                return;
            }

            if (typeof tab === 'string') {
                activeTab.value = tab;
            }
        });

        const {toggleProfile, openFeedbackForm, trackFairEvent} = FairComposition();
        const selectedFilter: Ref<Filter | null> = ref(null);

        const openFeedbackHandler = () => {
            openFeedbackForm(true, EFeedBackSource.click);
        };

        onMounted(() => {
            trackFairEvent('tab');
            if (activeTab.value) {
                const tabObject = tabs.value.find(el => el.name === activeTab.value) || null;
                if(tabObject){
                    trackFairEvent('tab', tabObject.key);
                }
            }
        });

        return {
            tabs,
            activeTab,
            toggleProfile,
            selectedFilter,
            filters,
            fairProfiles,
            filteredFairProfiles,
            isStarted,
            openFeedbackForm,
            openFeedbackHandler,

            trackFairEvent,
            // currentMoment
            momentOnMounted,
            momentOnUnmounted,
            currentMoment
        };
    },
    data: () => ({
        ready: false
    }),
    computed: {
        getBadgeStyle() {
            if (!this.fair || !this.fair.colorHex) {
                return null;
            }
            const fair = <Fair>this.fair;

            return {background: fair.colorHex};
        },
        fair(): Fair | null {
            return this.$store.getters['fairs/current'];
        },
        differenceInDays(): string {
            if (!this.fair || !this.fair.startDate) {
                return '';
            }

            const compareDate = moment().startOf('day');
            const diffInDays = this.fair.startDate.diff(compareDate, 'days');

            if (diffInDays > 1) {
                return 'in ' + diffInDays + ' Tagen';
            }
            if (diffInDays === 1) {
                return 'morgen';
            }

            return 'heute';
        }
    },
    mounted() {
        window.setTimeout(() => {
            this.ready = true;
        }, 10);
    },
    methods: {
        setFilter(filter: Filter) {
            if (filter.disabled === false) {
                if (!this.selectedFilter || this.selectedFilter.id !== filter.id) {
                    this.selectedFilter = filter;
                } else {
                    this.selectedFilter = null;
                }
            }
        },
        close() {
            const slug = this.$route?.params?.slug ?? null;
            if (slug && typeof slug === 'string') {
                this.ready = false;
                window.setTimeout(() => {
                    this.$router.push({name: 'fair', params: {slug: slug}});
                }, 100);

            }
        },
        openTab(tab: string) {
            if (this.$route.name) {
                this.$router.push({name: this.$route.name, params: {tab: tab}});
                const tabObject = this.tabs.find(el=> el.name === tab) || null;
                if(tabObject){
                    this.trackFairEvent('tab', tabObject.key);
                }
            }
        }
    }
});
</script>

<style scoped>

</style>
