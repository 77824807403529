import {onMounted, onUnmounted, ref} from "vue";
import moment from "moment";

/**
 * Current Moment Composition
 *
 * @param interval
 * @return {{onMounted: (callback: Function) => void, onUnmounted: (callback: Function) => void, currentMoment: Ref<UnwrapRef<moment.Moment>>}}
 */
export default (interval = 1000) => {
    const currentMoment = ref(moment());

    let timerInterval: number | null = null;
    onMounted(() => {
        timerInterval = window.setInterval(() => {
            currentMoment.value = moment();
        }, interval);

    })

    onUnmounted(() => {
        if (typeof timerInterval !== 'undefined' && timerInterval) {
            window.clearInterval(timerInterval);
            timerInterval = null;
        }
    })

    return {
        onMounted,
        onUnmounted,
        currentMoment
    }
}