<template>
    <div class="badge highlight">
        <h2>academix Thüringen <small>22.06.21 - 27.06.21</small></h2>
    </div>
    <div class="badge">
        <div class="img">
            <img
                src="img/logo-innogy.png"
                alt="">
        </div>
        <h2>innogy <small>Energiedienstleister</small></h2>
    </div>
    <div class="img video">
        <img
            src="img/placeholder-img.jpg"
            alt="">
    </div>
    <h3>Große Karrierechancen verdienen große Aufmerksamkeit</h3>
    <p>Große Karrierechancen verdienen große Aufmerksamkeit</p>
    <p />
    <p>
        In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die academix
        Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich Thüringer Unternehmen mit
        ihren Praktika, Abschlussarbeitsthemen und Direkteinstiegsmöglichkeiten. Damit ist die academix
        Thüringen die erste Adresse für alle, die gerne in Thüringen arbeiten möchten!
    </p>
    <p>
        Seit 2011 ist die Firmenkontaktmesse academix Thüringen die richtige Plattform für Thüringer Firmen,
        die auf der Suche nach qualifizierten Mitarbeitern sind.
    </p>
    <a
        title=""
        href=""
        class="btn primary">Ausbildung bei innogy (PDF)
    </a>
    <ul class="link-bar">
        <li>
            <a
                href=""
                title="">Impressum
            </a>
        </li>
        <li>
            <a
                href=""
                title="">Datenschutz
            </a>
        </li>
    </ul>
    <div class="company-footer grid no-gap">
        <div class="col-1-2">
            <div class="inner grey">
                <h3>Ihre Ansprechpartner</h3>
                <ul class="icon-text-list">
                    <li class="icon-user">
                        Sabrina Setlur
                    </li>
                    <li class="icon-mail">
                        <a
                            href=""
                            title="">sabrina.setlur@innogy.de
                        </a>
                    </li>
                    <li class="icon-phone">
                        032562 232423
                    </li>
                </ul>
                <ul class="icon-text-list">
                    <li class="icon-user">
                        Sabrina Setlur
                    </li>
                    <li class="icon-mail">
                        <a
                            href=""
                            title="">sabrina.setlur@innogy.de
                        </a>
                    </li>
                    <li class="icon-phone">
                        032562 232423
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-1-2">
            <div class="inner">
                <h3>Kontakt</h3>
                <ul class="icon-text-list">
                    <li class="icon-address">
                        Warsbergstraße 1<br>
                        99092 Erfurt<br>
                        Deutschland
                    </li>
                    <li class="icon-mail">
                        <a
                            href=""
                            title="">www.innogy.de
                        </a>
                    </li>
                </ul>
                <ul class="list-social">
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-facebook" />
                    </li>
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-youtube" />
                    </li>
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-twitter" />
                    </li>
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-xing" />
                    </li>
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-instagram" />
                    </li>
                    <li>
                        <a
                            href=""
                            title=""
                            class="icon-linkedin" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <ul class="pagination">
        <li class="disabled">
            A
        </li>
        <li class="disabled">
            B
        </li>
        <li>C</li>
        <li class="active">
            D
        </li>
        <li>E</li>
        <li>F</li>
        <li class="disabled">
            G
        </li>
        <li>H</li>
        <li>I</li>
        <li>J</li>
        <li>K</li>
        <li>L</li>
        <li>M</li>
        <li>N</li>
        <li>O</li>
        <li>P</li>
        <li class="disabled">
            Q
        </li>
        <li>R</li>
        <li>S</li>
        <li>T</li>
        <li>U</li>
        <li>V</li>
        <li>W</li>
        <li class="disabled">
            X
        </li>
        <li class="disabled">
            Y
        </li>
        <li>Z</li>
    </ul>

    <ul class="exhibitor-list">
        <li>
            <div class="img">
                <img
                    src="img/logo-innogy.png"
                    alt="">
            </div>
            <div class="content">
                <h2>Innogy</h2>
                Energiedienstleister
                <ul class="icon-list">
                    <li>
                        <button class="blue icon-video" />
                    </li>
                    <li>
                        <button class="green icon-chat" />
                    </li>
                    <li>
                        <button class="red icon-jobs" />
                    </li>
                </ul>
            </div>
            <div class="buttons">
                <button class="btn primary">
                    Details
                </button>
            </div>
        </li>
        <li>
            <div class="img">
                <img
                    src="img/logo-innogy.png"
                    alt="">
            </div>
            <div class="content">
                <h2>Innogy</h2>
                Energiedienstleister
                <ul class="icon-list">
                    <li>
                        <button class="blue icon-video" />
                    </li>
                    <li>
                        <button class="green icon-chat" />
                    </li>
                    <li>
                        <button class="red icon-jobs" />
                    </li>
                </ul>
            </div>
            <div class="buttons">
                <button class="btn primary">
                    Details
                </button>
            </div>
        </li>
        <li>
            <div class="img">
                <img
                    src="img/logo-innogy.png"
                    alt="">
            </div>
            <div class="content">
                <h2>Innogy</h2>
                Energiedienstleister
                <ul class="icon-list">
                    <li>
                        <button class="blue icon-video" />
                    </li>
                    <li>
                        <button class="green icon-chat" />
                    </li>
                    <li>
                        <button class="red icon-jobs" />
                    </li>
                </ul>
            </div>
            <div class="buttons">
                <button class="btn primary">
                    Details
                </button>
            </div>
        </li>
    </ul>
    <ul class="news-list">
        <li>
            <div class="img">
                <img
                    src="img/placeholder-news.jpg"
                    alt="">
            </div>
            <div class="content">
                <small>22. 04. 2021</small>
                <h2>Große Karrierechancen verdienen große Aufmerksamkeit</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
                <a
                    href=""
                    title=""
                    class="btn primary">Mehr
                </a>
            </div>
        </li>
        <li>
            <div class="img">
                <img
                    src="img/placeholder-news.jpg"
                    alt="">
            </div>
            <div class="content">
                <small>22. 04. 2021</small>
                <h2>Große Karrierechancen verdienen große Aufmerksamkeit</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
            </div>
        </li>
        <li>
            <div class="img">
                <img
                    src="img/placeholder-news.jpg"
                    alt="">
            </div>
            <div class="content">
                <small>22. 04. 2021</small>
                <h2>Große Karrierechancen verdienen große Aufmerksamkeit</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
            </div>
        </li>
    </ul>

    <ul class="partner-list">
        <li>
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
            <div class="content">
                <h2>Teag</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen!
                </p>
                <ul class="icon-text-list">
                    <li class="icon-phone">
                        032562 232423
                    </li>
                    <li class="icon-home">
                        www.innogy.de
                    </li>
                </ul>
            </div>
        </li>
        <li>
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
            <div class="content">
                <h2>Teag</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen!
                </p>
                <ul class="icon-text-list">
                    <li class="icon-phone">
                        032562 232423
                    </li>
                    <li class="icon-home">
                        <a
                            href=""
                            title="">www.innogy.de
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

    <ul class="partner-list small grid">
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
        <li class="col-1-3">
            <div class="img">
                <img
                    src="img/placeholder-logo.png"
                    alt="">
            </div>
        </li>
    </ul>

    <h1>Tag 1 / 22.06.</h1>
    <ul class="timeline">
        <li>
            <div class="content">
                <small class="now">10:00 Uhr - findet gerade statt</small>
                <h2>Wie bewerbe ich mich richtig?</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
                <button class="btn primary">
                    Teilnehmen
                </button>
            </div>
            <div class="img">
                <img
                    src="img/placeholder-img.jpg"
                    alt="">
            </div>
        </li>
        <li>
            <div class="content">
                <small><strong>10:00 Uhr</strong> - in 30 Minuten</small>
                <h2>Wie bewerbe ich mich richtig?</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
                <button class="btn primary">
                    Teilnehmen
                </button>
            </div>
            <div class="img">
                <img
                    src="img/placeholder-img.jpg"
                    alt="">
            </div>
        </li>
        <li>
            <div class="content">
                <small><strong>10:00 Uhr</strong> - in 30 Minuten</small>
                <h2>Wie bewerbe ich mich richtig?</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
                <button class="btn primary">
                    Teilnehmen
                </button>
            </div>
            <div class="img">
                <img
                    src="img/placeholder-img.jpg"
                    alt="">
            </div>
        </li>
        <li>
            <div class="content">
                <small><strong>10:00 Uhr</strong> - in 30 Minuten</small>
                <h2>Wie bewerbe ich mich richtig?</h2>
                <p>
                    In Thüringen warten vielfältige Berufsperspektiven - welche genau, das zeigt Dir die
                    academix Thüringen! Als erste Firmenkontaktmesse präsentiert sie ausschließlich
                    Thüringer Unternehmen mit ihren Praktika, Abschlussarbeitsthemen und
                </p>
                <button class="btn primary">
                    Teilnehmen
                </button>
            </div>
            <div class="img">
                <img
                    src="img/placeholder-img.jpg"
                    alt="">
            </div>
        </li>
    </ul>

    <h2>Ausgeschriebene Stellen</h2>
    <ul class="list-jobs">
        <li>
            <h2>Leiter mechanische Fertigung m/w - Einzelteilfertigung</h2>
            <div class="info">
                <span class="tag job">Stelle</span>
                <span class="label icon-tag">Vollzeit</span>
                <span class="label icon-tag">Arnstadt</span>
                <div class="buttons">
                    <a
                        href=""
                        title=""
                        class="btn primary">Details
                    </a>
                </div>
            </div>
        </li>
        <li>
            <h2>Leiter mechanische Fertigung m/w - Einzelteilfertigung</h2>
            <div class="info">
                <span class="tag apprenticeship">Stelle</span>
                <span class="label icon-tag">Vollzeit</span>
                <span class="label icon-tag">Arnstadt</span>
                <div class="buttons">
                    <a
                        href=""
                        title=""
                        class="btn primary">Details
                    </a>
                </div>
            </div>
        </li>
        <li>
            <h2>Leiter mechanische Fertigung m/w - Einzelteilfertigung</h2>
            <div class="info">
                <span class="tag internship">Stelle</span>
                <span class="label icon-tag">Vollzeit</span>
                <span class="label icon-tag">Arnstadt</span>
                <div class="buttons">
                    <a
                        href=""
                        title=""
                        class="btn primary">Details
                    </a>
                </div>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ExampleTemplates'
});
</script>

<style scoped>

</style>
