<template>
    <div
        v-for="day in programsByDate"
        :key="day.index">
        <h1>Tag {{ dayNumber(day) }} / {{ day.date.format('DD.MM') }}.</h1>

        <ul class="timeline">
            <li
                v-for="program in day.programs"
                :key="program.id">
                <div class="content">
                    <small :class="{now: isNow(program)}">{{ program.startDate.format('HH:mm') }} Uhr
                        <template v-if="isNow(program)">
                            - findet gerade statt
                        </template>
                    </small>
                    <h2>{{ program.title }}</h2>
                    <p
                        v-if="program.text"
                        v-html="program.text" />

                    <SLink
                        v-if="program.link && program.link.attributes && program.link.attributes.href"
                        :link="program.link"
                        class="btn primary">
                        Teilnehmen
                    </SLink>
                </div>
                <div
                    v-if="program.imageUrl"
                    class="img">
                    <img
                        :src="program.imageUrl"
                        alt="">
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import SLink from "@/components/base/SLink.vue";
import Fair from "@/models/Fair";
import Program from "@/models/Program";
import moment from "moment";
import {defineComponent, PropType} from "vue";

interface Day {
    programs: Program[],
    index: string,
    date: moment.Moment
}

export default defineComponent({
    name: "Programs",
    components: {
        SLink
    },
    props: {
        programs: {
            required: true,
            type: Array as PropType<Program[]>,
            default: () => ([])
        },
        fair: {
            required: true,
            type: Fair,
        },
        currentMoment: {
            required: true,
            type: Object as PropType<moment.Moment>
        }
    },
    computed: {
        programsByDate() {
            const days: Day[] = [];

            this.programs.forEach((program: Program) => {

                const startDate = program.startDate;
                if (startDate) {
                    const startOfDay = startDate.clone().startOf('day');
                    const dateIndex = startOfDay.format();
                    const index = days.findIndex(el => el.index === dateIndex);

                    if (index === -1) {
                        days.push({
                            programs: [program],
                            index: dateIndex,
                            date: startOfDay
                        })
                    } else {
                        days[index].programs.push(program)
                    }
                }

            })

            return days;
        }
    },
    methods: {
        dayNumber(day: Day): number {
            if (day.date.isSame(this.fair.startDate, 'day')) {
                return 1;
            }

            return day.date.diff(this.fair.startDate?.clone().startOf('day'), 'days') +1
        },
        /**
         * Check if a program is now
         *
         * @param program
         */
        isNow(program: Program) {
            return program.startDate && program.endDate && program.startDate.isSameOrBefore(this.currentMoment) && program.endDate.isSameOrAfter(this.currentMoment);
        }
    }
})
</script>

<style scoped>

</style>
